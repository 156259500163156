import Image from 'next/future/image'
import Link from 'next/link'
import React, { useMemo, useState } from 'react'
import SlideToggle from 'react-slide-toggle'

import styles from './styles.module.scss'
import { parseNotionMenu } from '../../utils/parsers'
import globalStyles from '../HomePage/styles/styles.module.scss'
import Search from '../Search'
import TreeMenu from '../TreeMenu'
import { type IDatabase } from '@/lib/types'

export default function Header({
    database,
    wrapperClass,
    isDatabasePage,
    currentPageId,
}: {
    database?: IDatabase | null
    wrapperClass?: string
    isDatabasePage?: boolean
    currentPageId?: string
}): JSX.Element {
    const [showMobileSearch, setShowMobileSearch] = useState(false)
    const [showTreeMenu, setShowTreeMenu] = useState(false)

    const menu = useMemo(() => parseNotionMenu(database), [database])

    return (
        <header className={`${styles.header} ${wrapperClass ?? ''}`}>
            <SlideToggle collapsed={true}>
                {({ toggle, setCollapsibleElement, toggleState }) => (
                    <>
                        <div className={globalStyles.container}>
                            <div className={styles.row}>
                                <Link href={'/'}>
                                    <a className={styles.logo}>
                                        <Image
                                            width={152}
                                            height={61}
                                            src={require('./images/logo.png')}
                                            alt={'Tekara'}
                                            aria-label={'Tekara'}
                                        />
                                    </a>
                                </Link>
                                <nav className={styles.nav}>
                                    <ul>
                                        {menu.map(
                                            (
                                                { title, url, isExternal },
                                                idx
                                            ) => {
                                                if (
                                                    isExternal &&
                                                    !url.includes('#')
                                                ) {
                                                    return (
                                                        <li key={`${idx}`}>
                                                            <a href={url}>
                                                                {title}
                                                            </a>
                                                        </li>
                                                    )
                                                }
                                                return (
                                                    <li key={`${idx}`}>
                                                        <Link
                                                            href={url}
                                                            scroll={
                                                                !url.includes(
                                                                    '#'
                                                                )
                                                            }
                                                        >
                                                            <a>{title}</a>
                                                        </Link>
                                                    </li>
                                                )
                                            }
                                        )}
                                    </ul>
                                </nav>
                                <div className={styles.info}>
                                    <a
                                        className={styles.info__tel}
                                        href="tel:+7 (495) 532-53-16"
                                    >
                                        +7 (495) 532-53-16
                                    </a>
                                    <span className={styles.info__days}>
                                        Пн-Пт: 8:00-19:00
                                    </span>
                                </div>
                                <div className={styles.mobileButtons}>
                                    {isDatabasePage ? (
                                        <button
                                            aria-label="Поиск"
                                            onClick={() => {
                                                setShowMobileSearch(true)
                                            }}
                                            className={
                                                styles.mobileButtonSearch
                                            }
                                        ></button>
                                    ) : null}
                                    <button
                                        aria-label="Мобильное меню"
                                        onClick={() => {
                                            isDatabasePage
                                                ? setShowTreeMenu(true)
                                                : toggle()
                                        }}
                                        className={`${styles.mobileMenu} ${
                                            toggleState === 'EXPANDED' ||
                                            toggleState === 'EXPANDING'
                                                ? styles.mobileMenuExpanded
                                                : ''
                                        }`}
                                    ></button>
                                </div>
                            </div>
                        </div>
                        <div
                            ref={setCollapsibleElement}
                            className={styles.toggleMenu}
                        >
                            {menu.map(({ title, url, isExternal }, idx) => {
                                if (isExternal && !url.includes('#')) {
                                    return (
                                        <div
                                            key={`${idx}`}
                                            className={styles.toggleMenuItem}
                                        >
                                            <a onClick={toggle} href={url}>
                                                {title}
                                            </a>
                                        </div>
                                    )
                                }
                                return (
                                    <div
                                        key={`${idx}`}
                                        className={styles.toggleMenuItem}
                                    >
                                        <Link
                                            href={url}
                                            scroll={!url.includes('#')}
                                        >
                                            <a onClick={toggle}>{title}</a>
                                        </Link>
                                    </div>
                                )
                            })}
                        </div>
                    </>
                )}
            </SlideToggle>
            {showMobileSearch ? (
                <div className={styles.mobileSearchOuter}>
                    <Search
                        onCloseMobileSearch={() => {
                            setShowMobileSearch(false)
                        }}
                    />
                </div>
            ) : null}
            {showTreeMenu && database ? (
                <TreeMenu
                    database={database}
                    currentPageId={currentPageId}
                    isMobileMenu={true}
                    onCloseMobileTree={() => {
                        setShowTreeMenu(false)
                    }}
                />
            ) : null}
        </header>
    )
}
