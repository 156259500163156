import React, { useRef, useState } from 'react'
import Highlighter from 'react-highlight-words'

import styles from './styles.module.scss'
import { type Props } from './types'
import Button from '../Button'
import ModalMessage from '../ModalMessage'
import NotionSnippet from '../NotionSnippet'

export default function Search({ onCloseMobileSearch }: Props): JSX.Element {
    const [input, setInput] = useState('')
    const [alert, setAlert] = useState(false)

    const refInput = useRef<HTMLInputElement>(null)

    const handleFocusInput = (): void => {
        if (refInput.current) {
            refInput.current.focus()
        }
    }

    return (
        <>
            <div
                onClick={() => {
                    setAlert(true)
                }}
                className={styles.wrapper}
            >
                <div className={styles.item} style={{ flex: 1 }}>
                    <div onClick={handleFocusInput} className={styles.icon} />
                    <input
                        ref={refInput}
                        className={styles.input}
                        placeholder={'Поиск по базе знаний'}
                        disabled={true}
                        onChange={() => {
                            setInput('')
                        }}
                    />
                </div>
                <div className={styles.item}>
                    <Button classNames={styles.button} type={'secondary'}>
                        Найти
                    </Button>
                    <button
                        onClick={onCloseMobileSearch}
                        className={styles.buttonClose}
                    ></button>
                </div>
                {input && (
                    <div className={styles.results}>
                        <a href={''} className={styles.resultsItem}>
                            <Highlighter
                                highlightClassName={styles.textHighlight}
                                searchWords={input.split(' ')}
                                autoEscape={true}
                                textToHighlight={'Работа с личным кабинетом'}
                            />
                        </a>
                        <a href={''} className={styles.resultsItem}>
                            <Highlighter
                                highlightClassName={styles.textHighlight}
                                searchWords={input.split(' ')}
                                autoEscape={true}
                                textToHighlight={'Личный кабинет поставщика'}
                            />
                        </a>
                    </div>
                )}
            </div>
            <NotionSnippet code={'search-in-development'}>
                {props => (
                    <ModalMessage
                        title={props.header}
                        description={props.content}
                        visible={alert}
                        image={'/assets/images/default-image.png'}
                        onClose={() => {
                            setAlert(false)
                        }}
                    />
                )}
            </NotionSnippet>
        </>
    )
}
