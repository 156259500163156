import { navigationSubLinkPageId } from '@/lib/config'
import { type IDatabase } from '@/lib/types'

interface ISignedUrl {
    title: string
    url: string
    isExternal: boolean
}

export const parseNotionMenu = (database?: IDatabase | null): ISignedUrl[] => {
    const signedUrls: ISignedUrl[] = []

    const findMenuPage =
        !!database &&
        database.results?.find(i => i.id === navigationSubLinkPageId)

    if (findMenuPage) {
        const properties = findMenuPage.properties

        if (
            properties &&
            Array.isArray(properties.Children?.relation) &&
            properties.Children.relation.length
        ) {
            for (const item of properties.Children.relation) {
                const page = database.results.find(i => i.id === item.id)

                if (page) {
                    const menuTitle =
                        Array.isArray(
                            page.properties['Menu-title'].rich_text
                        ) &&
                        page.properties['Menu-title'].rich_text[0]?.plain_text
                    const title =
                        Array.isArray(page.properties.Name.title) &&
                        page.properties.Name.title[0]?.plain_text
                    const slug =
                        Array.isArray(page.properties.Slug.rich_text) &&
                        page.properties.Slug.rich_text[0]?.plain_text
                    const externalUrl = page.properties['External-URL']?.url

                    const url = externalUrl ?? `/${slug}`

                    if ((externalUrl ?? slug) && title) {
                        signedUrls.push({
                            title: menuTitle || title,
                            url: url || '',
                            isExternal: !!externalUrl,
                        })
                    }
                }
            }
        }
    }

    return signedUrls
}
