import React, { type MouseEvent, useEffect } from 'react'
import PreloadImage from 'react-preload-image'

import styles from './styles.module.scss'
import { type Props } from './types'
import Image from '../Image'

export default function ModalMessage({
    title,
    description,
    visible,
    image,
    onClose,
}: Props): JSX.Element {
    useEffect(() => {
        const body = document.querySelector('body')
        if (body) {
            body.style.overflowY = visible ? 'hidden' : 'scroll'
        }
    }, [visible])

    if (!visible) {
        return <></>
    }

    const handleClickWrapper = (e: MouseEvent<HTMLDivElement>): void => {
        if ((e.target as Element).classList.contains(styles.layer)) {
            onClose()
        }
    }

    return (
        <div className={styles.layer} onClick={handleClickWrapper}>
            <div className={styles.content}>
                <div className={styles.row}>
                    <div className={styles.item}>
                        {image ? (
                            <PreloadImage
                                className={styles.preloadImage}
                                src={image}
                                lazy={true}
                            />
                        ) : (
                            <Image
                                width={115}
                                height={115}
                                alt={title}
                                src={require('./images/default.svg')}
                            />
                        )}
                    </div>
                    <div className={styles.item}>
                        <h3>{title}</h3>
                        <p>{description}</p>
                        <button onClick={onClose}>Понятно</button>
                    </div>
                </div>
                <button onClick={onClose} className={styles.close}></button>
            </div>
        </div>
    )
}
