import NextImage from 'next/future/image'
import React from 'react'

interface Props {
    width: number
    height: number
    alt: string
    src: string
    hideBlur?: boolean
}

export default function Image({
    src,
    alt,
    width,
    height,
    hideBlur,
    ...args
}: Props): JSX.Element {
    const shimmer = (w: number, h: number): string => `
  <svg width="${w}" height="${h}" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs>
      <linearGradient id="g">
        <stop stop-color="#333" offset="20%" />
        <stop stop-color="#222" offset="50%" />
        <stop stop-color="#333" offset="70%" />
      </linearGradient>
    </defs>
    <rect width="${w}" height="${h}" fill="#333" />
    <rect id="r" width="${w}" height="${h}" fill="url(#g)" />
    <animate xlink:href="#r" attributeName="x" from="-${w}" to="${w}" dur="1s" repeatCount="indefinite"  />
  </svg>`

    const toBase64 = (str: string): string =>
        typeof window === 'undefined'
            ? Buffer.from(str).toString('base64')
            : window.btoa(str)

    return (
        <NextImage
            src={src}
            width={width}
            height={height}
            alt={alt}
            placeholder={hideBlur ? 'empty' : 'blur'}
            blurDataURL={`data:image/svg+xml;base64,${toBase64(
                shimmer(width, height)
            )}`}
            style={{
                maxWidth: '100%',
                height: 'auto',
            }}
            {...args}
        />
    )
}
