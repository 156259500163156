import { useContext, useMemo } from 'react'

import { type Props } from './types'
import { SnippetsContext } from '../../context/snippets-context'

export default function NotionSnippet({ code, children }: Props): JSX.Element {
    const snippets = useContext(SnippetsContext)

    const props = useMemo(() => {
        return snippets.find(i => i.code === code)
    }, [snippets])

    if (!props) return children({})

    return children(props)
}
