import classNames from 'classnames'
import Link from 'next/link'
import { parsePageId } from 'notion-utils'
import React, { useEffect, useMemo, useState } from 'react'
import SlideToggle from 'react-slide-toggle'

import styles from './styles.module.scss'
import { type IPropertyItem, type ITreeMenuItem } from './types'
import { type IDatabase, type Properties } from '@/lib/types'
import { treeMenuRoutePageId } from '@/lib/config'

function WrapperMobileMenu({
    currentPageId,
    mobileSelectedItem,
    setSelectedMenuId,
    onCloseMobileTree,
}: {
    currentPageId?: string
    mobileSelectedItem: ITreeMenuItem
    setSelectedMenuId: (id: string) => void
    onCloseMobileTree?: () => void
}): JSX.Element {
    return (
        <>
            <div
                className={`${styles.mobileTreeMenuRowItem} ${
                    currentPageId === mobileSelectedItem.id
                        ? styles.mobileTreeMenuRowItemActive
                        : ''
                }`}
            >
                <Link href={mobileSelectedItem.url}>
                    <a onClick={onCloseMobileTree}>
                        {mobileSelectedItem.title}
                    </a>
                </Link>
            </div>
            {Array.isArray(mobileSelectedItem.children) ? (
                mobileSelectedItem.children.map((item, idx) => (
                    <MobileMenuItem
                        key={`mobile_${idx}`}
                        currentPageId={currentPageId}
                        setSelectedMenu={setSelectedMenuId}
                        item={item}
                        onCloseMobileTree={onCloseMobileTree}
                    />
                ))
            ) : (
                <></>
            )}
        </>
    )
}

function MobileMenuItem({
    item,
    currentPageId,
    setSelectedMenu,
    onCloseMobileTree,
}: {
    item: ITreeMenuItem
    currentPageId?: string
    setSelectedMenu: (id: string) => void
    onCloseMobileTree?: () => void
}): JSX.Element {
    if (Array.isArray(item.children) && item.children.length) {
        return (
            <div className={styles.mobileTreeMenuRowItem}>
                <div
                    onClick={() => {
                        setSelectedMenu(item.id)
                    }}
                    className={styles.mobileTreeMenuRowItemButton}
                >
                    {item.title}
                </div>
            </div>
        )
    }
    return (
        <div
            className={`${styles.mobileTreeMenuRowItem} ${
                currentPageId === item.id
                    ? styles.mobileTreeMenuRowItemActive
                    : ''
            }`}
        >
            <Link href={item.url}>
                <a onClick={onCloseMobileTree}>{item.title}</a>
            </Link>
        </div>
    )
}

function MenuItem({
    item,
    level,
    currentPageId,
}: {
    item: ITreeMenuItem
    level: number
    currentPageId?: string
}): JSX.Element {
    const [date, setDate] = useState<number>(Date.now)

    const findActive = (id: string, children: ITreeMenuItem[]): boolean => {
        return children.some(i => {
            if (i.id === id) {
                return true
            }
            return findActive(id, i.children)
        })
    }

    const hasChildren = item.children.length
    const isActivePage = currentPageId === item.id
    const hasChildrenActive =
        hasChildren && currentPageId
            ? findActive(currentPageId, item.children)
            : false

    useEffect(() => {
        if ((hasChildrenActive && !isActivePage) || isActivePage) {
            setTimeout(() => {
                setDate(Date.now)
            }, 100)
        }
    }, [hasChildrenActive, isActivePage])

    const itemClassNames = classNames(styles.treeMenuItemHeader, {
        [styles.treeMenuChildItemHeader]: level > 1,
        [styles.treeMenuItemHeaderActive]: isActivePage,
    })

    return (
        <div className={styles.treeMenuItem}>
            <SlideToggle
                collapsed={true}
                expandEvent={`${date} ${isActivePage ? '1' : '0'}`}
            >
                {({ toggle, setCollapsibleElement, toggleState }) => (
                    <>
                        <div
                            className={`${itemClassNames} ${
                                (toggleState === 'EXPANDED' ||
                                    toggleState === 'EXPANDING') &&
                                hasChildren
                                    ? styles.treeMenuItemHeaderExpanded
                                    : ''
                            }`}
                            style={{ paddingLeft: 32 * (level - 1) || 16 }}
                        >
                            {hasChildren ? (
                                <div
                                    className={styles.buttonWrapper}
                                    onClick={toggle}
                                >
                                    <button aria-label="Свернуть/развернуть меню"></button>
                                </div>
                            ) : null}
                            <Link href={item.url}>
                                <a
                                    style={
                                        hasChildren ? {} : { marginLeft: 28 }
                                    }
                                >
                                    {item.title}
                                </a>
                            </Link>
                        </div>
                        {hasChildren ? (
                            <div ref={setCollapsibleElement}>
                                {item.children.map((i, idx) => (
                                    <MenuItem
                                        key={`sub_${idx}`}
                                        item={i}
                                        currentPageId={currentPageId}
                                        level={level + 1}
                                    />
                                ))}
                            </div>
                        ) : null}
                    </>
                )}
            </SlideToggle>
        </div>
    )
}

export default function TreeMenu({
    database,
    currentPageId,
    isMobileMenu,
    onCloseMobileTree,
}: {
    database: IDatabase
    currentPageId?: string
    isMobileMenu?: boolean
    onCloseMobileTree?: () => void
}): JSX.Element {
    const [tree, setTree] = useState<ITreeMenuItem | null>(null)
    const [selectedMenuId, setSelectedMenuId] = useState(currentPageId)

    const mobileSelectedItem = useMemo(() => {
        if (tree && selectedMenuId) {
            const deep = (
                id: string,
                element?: ITreeMenuItem,
                parent?: ITreeMenuItem
            ): (ITreeMenuItem & { parent?: ITreeMenuItem }) | undefined => {
                if (element?.id === id) {
                    if (
                        Array.isArray(element.children) &&
                        element.children.length
                    ) {
                        return { ...element, parent }
                    } else if (parent?.id) {
                        return deep(parent.id, tree)
                    }
                }
                if (element && Array.isArray(element?.children)) {
                    for (const item of element.children) {
                        const elem = deep(id, item, element)

                        if (elem) {
                            return elem
                        }
                    }
                }
            }
            return deep(selectedMenuId, tree)
        }
    }, [selectedMenuId, tree])

    useEffect(() => {
        const getProperties = (
            id: string,
            prop: Properties
        ): IPropertyItem | undefined => {
            const slug = Array.isArray(prop.Slug.rich_text)
                ? prop.Slug.rich_text[0]?.plain_text
                : ''
            const menuTitle =
                Array.isArray(prop['Menu-title'].rich_text) &&
                prop['Menu-title'].rich_text[0]?.plain_text
            const title =
                Array.isArray(prop.Name.title) && prop.Name.title[0]?.plain_text
            const isHideSubMenu = prop['Submenu-hide']?.checkbox
            const children = Array.isArray(prop.Children?.relation)
                ? prop.Children.relation
                : []

            const url = slug

            if (!isHideSubMenu && slug && id) {
                return {
                    title: menuTitle || title,
                    url,
                    children,
                }
            }
        }

        const calculate = (startId): ITreeMenuItem | undefined => {
            const find = database.results.find(
                i => i.id === startId
            )?.properties

            const result = {
                id: startId,
                title: '',
                url: '',
                children: [],
            }

            if (find) {
                const getCurrentProperties = getProperties(startId, find)

                if (getCurrentProperties) {
                    result.id = startId
                    result.title = getCurrentProperties.title
                    result.url = getCurrentProperties.url
                } else {
                    return
                }

                if (
                    Array.isArray(find.Children?.relation) &&
                    find.Children.relation.length
                ) {
                    for (const item of find.Children.relation) {
                        const findChild = database.results.find(
                            i => i.id === item.id
                        )?.properties
                        if (findChild) {
                            const getChildProperties = getProperties(
                                item.id,
                                findChild
                            )
                            if (getChildProperties) {
                                // @ts-ignore
                                result.children.push({
                                    id: item.id,
                                    title: getChildProperties.title,
                                    url: getChildProperties.url,
                                    children: getChildProperties.children.length
                                        ? getChildProperties.children
                                              .map(i =>
                                                  calculate(
                                                      parsePageId(i.id, {
                                                          uuid: true,
                                                      })
                                                  )
                                              )
                                              .filter(i => i)
                                        : [],
                                })
                            }
                        }
                    }
                }
            }

            return result
        }

        const collection = calculate(
            parsePageId(treeMenuRoutePageId, { uuid: true })
        )

        if (collection) {
            setTree(collection)
        }
    }, [database])

    if (!tree) return <></>

    return isMobileMenu ? (
        <div className={styles.mobileTreeMenu}>
            <div className={styles.mobileTreeMenuHeader}>
                <button
                    aria-label="Вернуться назад"
                    disabled={
                        selectedMenuId === treeMenuRoutePageId ||
                        mobileSelectedItem?.id === treeMenuRoutePageId
                    }
                    className={styles.mobileTreeMenuHeaderBack}
                    onClick={() => {
                        const parentId = mobileSelectedItem?.parent?.id
                        if (parentId) {
                            setSelectedMenuId(parentId)
                        }
                    }}
                ></button>
                <div className={styles.mobileTreeMenuHeaderTitle}>
                    {selectedMenuId === treeMenuRoutePageId
                        ? 'Содержание'
                        : mobileSelectedItem?.title}
                </div>
                <button
                    aria-label="Закрыть меню"
                    onClick={onCloseMobileTree}
                    className={styles.mobileTreeMenuHeaderClose}
                ></button>
            </div>
            <div className={styles.mobileTreeMenuRow}>
                {mobileSelectedItem ? (
                    <WrapperMobileMenu
                        currentPageId={currentPageId}
                        setSelectedMenuId={setSelectedMenuId}
                        onCloseMobileTree={onCloseMobileTree}
                        mobileSelectedItem={mobileSelectedItem}
                    />
                ) : (
                    <></>
                )}
            </div>
        </div>
    ) : (
        <div className={styles.treeMenu}>
            {tree.children.map((i, idx) => (
                <MenuItem
                    key={`sub_${idx}`}
                    currentPageId={currentPageId}
                    item={i}
                    level={1}
                />
            ))}
        </div>
    )
}
