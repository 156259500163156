import classnames from 'classnames'
import React from 'react'

import styles from './styles.module.scss'

interface Props {
    children: React.ReactNode
    onClick?: () => void
    icon?: 'arrow'
    type?: 'primary' | 'secondary'
    disabled?: boolean
    classNames?: string
    isSubmit?: boolean
}

export default function Button({
    children,
    onClick,
    icon,
    type = 'primary',
    disabled,
    classNames,
    isSubmit,
}: Props): JSX.Element {
    const className = classnames([
        styles.button,
        icon ? styles[icon] : styles['no-icon'],
        classNames ?? '',
        {
            [styles.primary]: type === 'primary',
            [styles.secondary]: type === 'secondary',
        },
    ])

    return (
        <button
            type={isSubmit ? 'submit' : undefined}
            disabled={disabled}
            onClick={onClick}
            className={className}
        >
            {children}
        </button>
    )
}
